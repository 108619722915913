import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../app/store';

interface Location {
  id: string;
  name: string;
  coordinates: google.maps.LatLngLiteral;
  isEditable: boolean;
}

export interface AppStorageState {
  locations: Location[];
}

const initialState: AppStorageState = {
  locations: [
    {
      id: 'w21z3kx7f',
      name: 'NUS USC Swimming Pool',
      coordinates: {
        lat: 1.299817,
        lng: 103.775949,
      },
      isEditable: false,
    },
    {
      id: '9mud5ckzm',
      name: 'TRANSDEC',
      coordinates: {
        lat: 32.703445,
        lng: -117.250325,
      },
      isEditable: false,
    },
  ],
};

export const appStorageSlice = createSlice({
  name: 'appStorage',
  initialState,
  reducers: {
    addLocation: (state, action: PayloadAction<Location>) => {
      state.locations.push(action.payload);
    },
    deleteLocation: (state, action: PayloadAction<string>) => {
      state.locations = state.locations.filter(
        (location) => location.id !== action.payload
      );
    },
  },
});

export const { addLocation, deleteLocation } = appStorageSlice.actions;

export const selectLocations = (state: RootState) => state.appStorage.locations;

export default appStorageSlice.reducer;
