import roslib from 'roslib';
import { EventEmitter2 } from 'eventemitter2';
import { RosSettingsState } from '../reducers/rosSettingsSlice';

interface RosOptions {
  url?: string;
  groovyCompatibility?: boolean;
  transportLibrary?: 'websocket' | 'socket.io' | RTCPeerConnection;
  transportOptions?: RTCDataChannelInit;
}

interface ActionClient {
  feedbackListener: roslib.Topic;
  statusListener: roslib.Topic;
  resultListener: roslib.Topic;
  goalTopic: roslib.Topic;
  cancelTopic: roslib.Topic;
}

class Ros {
  odomListener?: roslib.Topic;

  headingListener?: roslib.Topic;

  sonarInfoListener?: roslib.Topic;

  sonarDataListener?: roslib.Topic;

  navigateClient?: roslib.Service;

  resetClient?: roslib.Service;

  locomotionClient?: roslib.ActionClient;

  private ros: roslib.Ros;

  private options: RosOptions;

  constructor(options?: RosOptions) {
    this.options = options ?? {};
    this.ros = new roslib.Ros(this.options);
  }

  on(eventName: string, callback: (event: string | string[]) => void) {
    this.ros.on(eventName, callback);
  }

  connect(url: string) {
    this.ros.close();
    (this.ros as unknown as EventEmitter2).removeAllListeners();
    this.ros = new roslib.Ros({ ...this.options, url });
    this.options.url = url;
  }

  reconnect() {
    if (!this.options.url) {
      return;
    }

    this.ros.connect(this.options.url);
  }

  updateNames(settings: Omit<RosSettingsState, 'hostname' | 'port'>) {
    this.odomListener?.unsubscribe();
    this.headingListener?.unsubscribe();
    this.sonarInfoListener?.unsubscribe();
    this.sonarDataListener?.unsubscribe();

    const locomotionClient = this.locomotionClient as ActionClient | undefined;
    locomotionClient?.feedbackListener.unsubscribe();
    locomotionClient?.statusListener.unsubscribe();
    locomotionClient?.resultListener.unsubscribe();
    locomotionClient?.goalTopic.unadvertise();
    locomotionClient?.cancelTopic.unadvertise();

    const namespace =
      settings.namespace === '' ? '/' : `/${settings.namespace}/`;

    this.odomListener = new roslib.Topic({
      ros: this.ros,
      name: namespace + settings.topics.odom,
      messageType: 'nav_msgs/Odometry',
      throttle_rate: 100,
    });

    this.headingListener = new roslib.Topic({
      ros: this.ros,
      name: namespace + settings.topics.heading,
      messageType: 'geometry_msgs/Vector3Stamped',
      throttle_rate: 100,
    });

    this.sonarInfoListener = new roslib.Topic({
      ros: this.ros,
      name: namespace + settings.topics.sonar.info,
      messageType: 'bb_msgs/SonarInfo',
    });

    this.sonarDataListener = new roslib.Topic({
      ros: this.ros,
      name: namespace + settings.topics.sonar.data,
      messageType: 'bb_msgs/SonarWorld',
    });

    this.navigateClient = new roslib.Service({
      ros: this.ros,
      name: namespace + settings.services.navigate,
      serviceType: 'bb_msgs/navigate2d',
    });

    this.resetClient = new roslib.Service({
      ros: this.ros,
      name: namespace + settings.services.reset,
      serviceType: 'bb_msgs/ResetPose',
    });

    this.locomotionClient = new roslib.ActionClient({
      ros: this.ros,
      serverName: namespace + settings.actions.locomotion,
      actionName: 'bb_msgs/LocomotionAction',
      timeout: 0,
    });
  }
}

const ros = new Ros();

export default ros;
