import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../app/store';

export interface RosSettingsState {
  hostname: string;
  port: number;

  namespace: string;
  topics: {
    odom: string;
    heading: string;
    sonar: {
      info: string;
      data: string;
    };
  };
  services: {
    navigate: string;
    reset: string;
  };
  actions: {
    locomotion: string;
  };
}

const initialState: RosSettingsState = {
  hostname: 'localhost',
  port: 9090,

  namespace: 'auv4',
  topics: {
    odom: 'nav/odom_ned',
    heading: 'nav/rpy_ned',
    sonar: {
      info: 'sonar/info',
      data: 'sonar/data',
    },
  },
  services: {
    navigate: 'navigate2D',
    reset: 'nav/2d/reset',
  },
  actions: {
    locomotion: 'LocomotionServer',
  },
};

export const rosSettingsSlice = createSlice({
  name: 'rosSettings',
  initialState,
  reducers: {
    update: (_, action: PayloadAction<RosSettingsState>) => action.payload,
  },
});

export const { update: updateRosSettings } = rosSettingsSlice.actions;

export const selectRosSettings = (state: RootState) => state.rosSettings;

export default rosSettingsSlice.reducer;
