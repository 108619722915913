import React, { FC, useMemo } from 'react';
import { Box, BoxProps, Heading, Text, VStack } from '@chakra-ui/react';
import { Vector3 } from 'roslib';
import { useAppSelector } from '../../app/hook';
import { selectLocations } from '../../reducers/appStorageSlice';
import { selectOriginId } from '../../reducers/mapSettingsSlice';
import { selectSensorData } from '../../reducers/sensorDataSlice';
import { latLngFromOdom } from '../../app/utils';

const Status: FC<BoxProps> = (props) => {
  const { google } = window;
  const sensorData = useAppSelector(selectSensorData);
  const originId = useAppSelector(selectOriginId);
  const locations = useAppSelector(selectLocations);

  const origin = useMemo(
    () => locations.find((location) => location.id === originId)?.coordinates,
    [locations, originId]
  );
  const offset =
    sensorData.offsets[sensorData.offsets.length - 1] ?? new Vector3();
  const distance =
    google && origin
      ? google.maps.geometry.spherical.computeLength(
          sensorData.offsets.map(
            (offset) => new google.maps.LatLng(latLngFromOdom(origin, offset))
          )
        )
      : 0;

  return (
    <Box bg="white" w="260px" px={6} py={5} borderRadius={4} {...props}>
      <VStack align="start">
        <Heading size="md" fontWeight="semibold" alignSelf="center" mb={2}>
          Current Status
        </Heading>
        <Text>
          <Text as="span" fontWeight="semibold">
            Heading:
          </Text>{' '}
          {sensorData.heading.toFixed(1)}&deg;
        </Text>
        <Text>
          <Text as="span" fontWeight="semibold">
            Odometry:
          </Text>{' '}
          {offset.x.toFixed(2)} m, {offset.y.toFixed(2)} m
        </Text>
        <Text>
          <Text as="span" fontWeight="semibold">
            Total Distance:
          </Text>{' '}
          {distance.toFixed(2)} m
        </Text>
      </VStack>
    </Box>
  );
};

export default Status;
