import React, { FC, useEffect } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  StackProps,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { UseFormRegister, UseFormSetValue, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import {
  addLocation,
  deleteLocation,
  selectLocations,
} from '../../../reducers/appStorageSlice';
import {
  hideLocation,
  selectLocation,
  showLocation,
} from '../../../reducers/mapOverlaySlice';
import {
  MapSettingsState,
  resetOrigin,
  selectOriginId,
  setOriginId,
} from '../../../reducers/mapSettingsSlice';
import { encode } from '../../../app/utils';

interface Props extends StackProps {
  registerMapSettings: UseFormRegister<MapSettingsState>;
  setMapSettingsValue: UseFormSetValue<MapSettingsState>;
}

interface LocationData {
  name: string;
}

const LocationControl: FC<Props> = ({
  registerMapSettings,
  setMapSettingsValue,
  ...props
}) => {
  const locations = useAppSelector(selectLocations);
  const location = useAppSelector(selectLocation);
  const originId = useAppSelector(selectOriginId);
  const dispatch = useAppDispatch();

  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<LocationData>({
    shouldUnregister: true,
  });

  const isLocationEditable = locations.find(
    (location) => location.id === originId
  )?.isEditable;

  const handleLocationDelete = () => {
    dispatch(deleteLocation(originId));
    dispatch(resetOrigin());
    setMapSettingsValue('origin.id', locations[0].id);
  };

  const handleLocationConfirm = (data: LocationData) => {
    if (!location.coordinates) {
      return;
    }

    const id = encode(location.coordinates);

    dispatch(
      addLocation({
        id,
        name: data.name,
        coordinates: location.coordinates,
        isEditable: true,
      })
    );
    dispatch(hideLocation());
    dispatch(setOriginId(id));
    setMapSettingsValue('origin.id', id);

    toast({
      description: 'New location added.',
      status: 'success',
      isClosable: true,
    });
  };

  useEffect(clearErrors, [location.coordinates, clearErrors]);

  return location.visible ? (
    <VStack {...props}>
      <FormControl
        display="flex"
        justifyContent="space-between"
        isInvalid={errors.name !== undefined}
      >
        <FormLabel mb={0} py={1} flexShrink={0} transition="none">
          Name
        </FormLabel>
        <Box maxW={40}>
          <Input
            size="sm"
            placeholder="TRANSDEC"
            {...register('name', {
              validate: {
                notEmpty: (value) => value !== '' || 'Name cannot be empty',
                uniqueName: (value) =>
                  !locations.map((location) => location.name).includes(value) ||
                  'Name must be unique',
                uniqueCoordinates: () =>
                  location.coordinates
                    ? !locations
                        .map((location) => location.id)
                        .includes(encode(location.coordinates)) ||
                      'Coordinates must be unique'
                    : true,
              },
            })}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </Box>
      </FormControl>
      <Flex w="100%" justify="space-between">
        <Text fontWeight="semibold">Latitude</Text>
        <Text w={40}>{location.coordinates?.lat.toFixed(6)}</Text>
      </Flex>
      <Flex w="100%" justify="space-between">
        <Text fontWeight="semibold">Longitude</Text>
        <Text w={40}>{location.coordinates?.lng.toFixed(6)}</Text>
      </Flex>
      <ButtonGroup size="sm" width="100%" spacing={4} pt={2}>
        <Button
          onClick={handleSubmit(handleLocationConfirm)}
          disabled={!location.coordinates}
          isFullWidth
        >
          Confirm
        </Button>
        <Button
          variant="outline"
          onClick={() => dispatch(hideLocation())}
          isFullWidth
        >
          Cancel
        </Button>
      </ButtonGroup>
    </VStack>
  ) : (
    <VStack {...props}>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <FormLabel mb={0} flexShrink={0}>
          Location
        </FormLabel>
        <Select size="sm" maxW={40} {...registerMapSettings('origin.id')}>
          {locations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <ButtonGroup size="sm" w="100%" spacing={4} pt={2}>
        <Button onClick={() => dispatch(showLocation())} isFullWidth>
          Add
        </Button>
        <Button
          onClick={handleLocationDelete}
          disabled={!isLocationEditable}
          isFullWidth
        >
          Delete
        </Button>
      </ButtonGroup>
    </VStack>
  );
};

export default LocationControl;
