import React, { FC, useMemo } from 'react';
import { Marker, Polygon, Polyline } from '@react-google-maps/api';
import { useAppSelector } from '../../app/hook';
import { selectMapSettings } from '../../reducers/mapSettingsSlice';
import { selectSensorData } from '../../reducers/sensorDataSlice';
import { selectLocations } from '../../reducers/appStorageSlice';
import { selectWaypoint } from '../../reducers/mapOverlaySlice';
import { latLngFromOdom } from '../../app/utils';

const FrequentRender: FC = () => {
  const { google } = window;

  const mapSettings = useAppSelector(selectMapSettings);
  const sensorData = useAppSelector(selectSensorData);
  const waypoint = useAppSelector(selectWaypoint);
  const locations = useAppSelector(selectLocations);

  const origin = useMemo(
    () =>
      locations.find((location) => location.id === mapSettings.origin.id)
        ?.coordinates ?? { lat: 0, lng: 0 },
    [locations, mapSettings.origin.id]
  );

  const position = latLngFromOdom(
    origin,
    sensorData.offsets[sensorData.offsets.length - 1]
  );
  const path = sensorData.offsets.map((offset) =>
    latLngFromOdom(origin, offset)
  );
  const sonarFOV = (() => {
    const { offsets, heading } = sensorData;
    const position = latLngFromOdom(origin, offsets[offsets.length - 1]);
    const { minAngle, maxAngle, range } = sensorData.sonar.info;
    const [leftPoint, rightPoint] = [minAngle, maxAngle].map((angle) =>
      google.maps.geometry.spherical
        .computeOffset(new google.maps.LatLng(position), range, heading + angle)
        .toJSON()
    );

    return [leftPoint, position, rightPoint];
  })();
  const sonarMap = sensorData.sonar.data.map((polygon) =>
    polygon.points.map((point) => latLngFromOdom(origin, point))
  );

  return (
    <>
      {mapSettings.path.visible && (
        <Polyline options={mapSettings.path} path={path} />
      )}
      {mapSettings.sonarMap.visible && (
        <Polygon options={mapSettings.sonarMap} paths={sonarMap} />
      )}
      {mapSettings.sonarFOV.visible && (
        <Polyline options={mapSettings.sonarFOV} path={sonarFOV} />
      )}
      {mapSettings.vehicle.visible && (
        <Marker
          icon={{
            ...mapSettings.vehicle,
            rotation: sensorData.heading,
          }}
          position={position}
        />
      )}
      {waypoint.current?.coordinates && (
        <Polyline
          options={{
            strokeOpacity: 0,
            icons: [
              {
                icon: {
                  path: 'M 0, -1, 0, 1',
                  strokeColor: mapSettings.waypoint.strokeColor,
                  strokeOpacity: 1,
                  scale: mapSettings.waypoint.strokeWeight ?? 1,
                },
                offset: '0',
                repeat: `${(mapSettings.waypoint.strokeWeight ?? 1) * 5}px`,
              },
            ],
          }}
          path={[position, waypoint.current.coordinates]}
        />
      )}
      {waypoint.visible && waypoint.pending?.coordinates && (
        <Polyline
          options={{
            strokeOpacity: 0,
            icons: [
              {
                icon: {
                  path: 'M 0, -1, 0, 1',
                  strokeColor: mapSettings.waypoint.strokeColor,
                  strokeOpacity: 0.5,
                  scale: mapSettings.waypoint.strokeWeight ?? 1,
                },
                offset: '0',
                repeat: `${(mapSettings.waypoint.strokeWeight ?? 1) * 5}px`,
              },
            ],
          }}
          path={[position, waypoint.pending.coordinates]}
        />
      )}
    </>
  );
};

export default FrequentRender;
