import React, { FC } from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
import { MapSettingsState } from '../../../reducers/mapSettingsSlice';

interface Props extends FormControlProps {
  name:
    | 'grid'
    | 'vehicle'
    | 'path'
    | 'sonarFOV'
    | 'sonarMap'
    | 'origin'
    | 'waypoint';
  register: UseFormRegister<MapSettingsState>;
  hideOpacity?: boolean;
}

const StrokeControl: FC<Props> = ({
  name,
  register,
  hideOpacity,
  ...props
}) => (
  <>
    <FormControl
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <FormLabel mb={0} flexShrink={0}>
        Stroke Color
      </FormLabel>
      <Input
        size="sm"
        maxW={24}
        placeholder="#000000"
        {...register(`${name}.strokeColor`)}
      />
    </FormControl>

    {!hideOpacity && (
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        {...props}
      >
        <FormLabel mb={0} flexShrink={0}>
          Stroke Opacity
        </FormLabel>
        <NumberInput size="sm" min={0} max={1} maxW={24}>
          <NumberInputField
            placeholder="1"
            {...register(`${name}.strokeOpacity`, {
              valueAsNumber: true,
            })}
          />
        </NumberInput>
      </FormControl>
    )}

    <FormControl
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <FormLabel mb={0} flexShrink={0}>
        Stroke Weight
      </FormLabel>
      <NumberInput size="sm" min={0} maxW={24}>
        <NumberInputField
          placeholder="1"
          {...register(`${name}.strokeWeight`, {
            valueAsNumber: true,
          })}
        />
      </NumberInput>
    </FormControl>
  </>
);

export default StrokeControl;
