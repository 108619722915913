import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  HStack,
  Input,
} from '@chakra-ui/react';
import {
  DrawingManager,
  GoogleMap,
  Marker,
  Polyline,
  StandaloneSearchBox,
  useJsApiLoader,
} from '@react-google-maps/api';
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useJsApiLoader';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { selectLocations } from '../../reducers/appStorageSlice';
import {
  selectMapOverlay,
  setLocation,
  setPendingWaypointCoordinates,
} from '../../reducers/mapOverlaySlice';
import { selectMapSettings } from '../../reducers/mapSettingsSlice';
import { encode } from '../../app/utils';
import FrequentRender from './FrequentRender';

const apiOptions: UseLoadScriptOptions = {
  googleMapsApiKey: 'AIzaSyDqVnA7J4XRRp0Q5Dv2c9KVyYvmdX66iOc',
  libraries: ['drawing', 'geometry', 'places'],
};

const mapOptions = {
  disableDefaultUI: true,
  mapTypeId: 'hybrid',
  tilt: 0,
  zoom: 20,
};

const Map: FC<BoxProps> = (props) => {
  const { google } = window;
  const { isLoaded } = useJsApiLoader(apiOptions);

  const mapSettings = useAppSelector(selectMapSettings);
  const mapOverlay = useAppSelector(selectMapOverlay);
  const locations = useAppSelector(selectLocations);
  const dispatch = useAppDispatch();

  const [map, setMap] = useState<google.maps.Map>();
  const [locationMarker, setLocationMarker] = useState<google.maps.Marker>();
  const [waypointMarker, setWaypointMarker] = useState<google.maps.Marker>();
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [gridLines, setGridLines] = useState<google.maps.LatLngLiteral[][]>([]);

  const origin = useMemo(
    () =>
      locations.find((location) => location.id === mapSettings.origin.id)
        ?.coordinates,
    [locations, mapSettings.origin.id]
  );

  const updateGrid = () => {
    const bounds = map?.getBounds();
    const zoom = map?.getZoom();

    if (!bounds || !zoom) {
      return;
    }

    setGridLines([]);

    if (zoom < mapSettings.grid.minZoom) {
      return;
    }

    const north = bounds.getNorthEast().lat();
    const south = bounds.getSouthWest().lat();
    const east = bounds.getNorthEast().lng();
    const west = bounds.getSouthWest().lng();

    const degreesY = mapSettings.grid.distance / 111111;
    const degreesX =
      degreesY / Math.cos((bounds.getCenter().lat() / 180) * Math.PI);

    const southEdge = Math.ceil(south / degreesY) * degreesY;
    const westEdge = Math.ceil(west / degreesX) * degreesX;

    for (let lat = southEdge; lat < north; lat += degreesY) {
      setGridLines((gridLines) => [
        ...gridLines,
        [
          {
            lat,
            lng: east,
          },
          {
            lat,
            lng: west,
          },
        ],
      ]);
    }

    for (let lng = westEdge; lng < east; lng += degreesX) {
      setGridLines((gridLines) => [
        ...gridLines,
        [
          {
            lat: north,
            lng,
          },
          {
            lat: south,
            lng,
          },
        ],
      ]);
    }
  };

  const setMapViewport = () => {
    if (!map || !searchBox) {
      return;
    }

    const places = searchBox.getPlaces();

    if (places?.length === 0) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    places?.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        return;
      }

      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });

    map.fitBounds(bounds);
  };

  const onLocationMarkerComplete = (marker: google.maps.Marker) => {
    locationMarker?.setMap(null);
    setLocationMarker(marker);
    dispatch(setLocation(marker.getPosition()?.toJSON()));
  };

  const onWaypointMarkerComplete = (marker: google.maps.Marker) => {
    waypointMarker?.setMap(null);
    setWaypointMarker(marker);
    dispatch(setPendingWaypointCoordinates(marker.getPosition()?.toJSON()));
  };

  return (
    <Box {...props}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ height: '100%' }}
          options={mapOptions}
          center={origin}
          onIdle={updateGrid}
          onBoundsChanged={() => searchBox?.setBounds(map?.getBounds() ?? null)}
          onLoad={setMap}
        >
          {mapSettings.grid.visible &&
            gridLines.map((gridLine) => (
              <Polyline
                key={gridLine.map((point) => encode(point)).join('')}
                options={mapSettings.grid}
                path={gridLine}
              />
            ))}
          {mapSettings.origin.visible && origin && (
            <Marker icon={mapSettings.origin} position={origin} />
          )}
          {mapOverlay.waypoint.current?.coordinates && (
            <Marker
              icon={{
                ...mapSettings.waypoint,
                path: 'M -2,-2 2,2 M 2,-2 -2,2',
              }}
              position={mapOverlay.waypoint.current.coordinates}
            />
          )}
          <FrequentRender />
          <DrawingManager
            options={{
              drawingControl: false,
              drawingMode: mapOverlay.location.visible
                ? google.maps.drawing.OverlayType.MARKER
                : null,
            }}
            onMarkerComplete={onLocationMarkerComplete}
            onUnmount={() => locationMarker?.setMap(null)}
          />
          <DrawingManager
            options={{
              drawingControl: false,
              drawingMode: mapOverlay.waypoint.visible
                ? google.maps.drawing.OverlayType.MARKER
                : null,
            }}
            onMarkerComplete={onWaypointMarkerComplete}
            onUnmount={() => waypointMarker?.setMap(null)}
          />
          <HStack spacing={0} gridGap={2} wrap="wrap" m={2}>
            <ButtonGroup isAttached>
              <Button
                background="white"
                _hover={{ bg: 'gray.100' }}
                _active={{ bg: 'gray.300' }}
                color="gray.800"
                border="1px solid"
                borderColor="gray.300"
                onClick={() => map?.setMapTypeId('roadmap')}
              >
                Map
              </Button>
              <Button
                background="white"
                _hover={{ bg: 'gray.100' }}
                _active={{ bg: 'gray.300' }}
                color="gray.800"
                border="1px solid"
                borderLeft={0}
                borderColor="gray.300"
                onClick={() => map?.setMapTypeId('hybrid')}
              >
                Satellite
              </Button>
            </ButtonGroup>
            <Box w={['100%', '400px']}>
              <StandaloneSearchBox
                onPlacesChanged={setMapViewport}
                onLoad={setSearchBox}
              >
                <Input
                  placeholder="Search..."
                  bg="white"
                  borderColor="gray.300"
                />
              </StandaloneSearchBox>
            </Box>
          </HStack>
        </GoogleMap>
      )}
    </Box>
  );
};

export default Map;
