import React, { FC } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  NumberInput,
  NumberInputField,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import {
  RosSettingsState,
  selectRosSettings,
  updateRosSettings,
} from '../../reducers/rosSettingsSlice';

type Props = Omit<ModalProps, 'children'>;

const SettingsModal: FC<Props> = (props) => {
  const rosSettings = useAppSelector(selectRosSettings);
  const dispatch = useAppDispatch();

  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: rosSettings,
  });

  const onSubmit = (settings: RosSettingsState) => {
    dispatch(updateRosSettings(settings));
    props.onClose();

    if (!toast.isActive('saved')) {
      toast({
        id: 'saved',
        description: 'Settings saved.',
        status: 'success',
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      onEsc={() => reset(rosSettings)}
      onOverlayClick={() => reset(rosSettings)}
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Settings</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isInvalid={errors.hostname !== undefined} isRequired>
                <FormLabel>Hostname</FormLabel>
                <Input
                  placeholder="Hostname or IP address (e.g. vehicle's IP or localhost)"
                  // Disable HTML validation
                  isRequired={false}
                  {...register('hostname', { required: 'This is required' })}
                />
                <FormErrorMessage>
                  {errors.hostname && errors.hostname.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.port !== undefined} isRequired>
                <FormLabel>Port</FormLabel>
                <NumberInput
                  precision={0}
                  // Disable HTML validation
                  isRequired={false}
                >
                  <NumberInputField
                    placeholder="TCP/IP port (default is 9090)"
                    {...register('port', {
                      required: 'This is required',
                      min: {
                        value: 0,
                        message: 'Port number must be between 0 and 65535',
                      },
                      max: {
                        value: 65535,
                        message: 'Port number must be between 0 and 65535',
                      },
                    })}
                  />
                </NumberInput>
                <FormErrorMessage>
                  {errors.port && errors.port.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel>Namespace</FormLabel>
                <Input
                  placeholder="Namespace (the global namespace if left empty)"
                  {...register('namespace')}
                />
              </FormControl>

              <Divider />

              <Heading size="md" fontWeight="semibold" alignSelf="start">
                Topics
              </Heading>

              <FormControl
                isInvalid={errors.topics?.odom !== undefined}
                isRequired
              >
                <FormLabel>Odometry</FormLabel>
                <Input
                  placeholder="Odometry topic (NED is assumed)"
                  // Disable HTML validation
                  isRequired={false}
                  {...register('topics.odom', { required: 'This is required' })}
                />
                <FormErrorMessage>
                  {errors.topics?.odom && errors.topics?.odom.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.topics?.heading !== undefined}
                isRequired
              >
                <FormLabel>Heading</FormLabel>
                <Input
                  placeholder="Heading topic (NED is assumed)"
                  // Disable HTML validation
                  isRequired={false}
                  {...register('topics.heading', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.topics?.heading && errors.topics?.heading.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.topics?.sonar?.info !== undefined}
                isRequired
              >
                <FormLabel>Sonar Info</FormLabel>
                <Input
                  placeholder="Sonar info topic (NED is assumed)"
                  // Disable HTML validation
                  isRequired={false}
                  {...register('topics.sonar.info', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.topics?.sonar?.info &&
                    errors.topics?.sonar.info.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.topics?.sonar?.data !== undefined}
                isRequired
              >
                <FormLabel>Sonar Data</FormLabel>
                <Input
                  placeholder="Sonar data topic (NED is assumed)"
                  // Disable HTML validation
                  isRequired={false}
                  {...register('topics.sonar.data', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.topics?.sonar?.data &&
                    errors.topics?.sonar.data.message}
                </FormErrorMessage>
              </FormControl>

              <Divider />

              <Heading size="md" fontWeight="semibold" alignSelf="start">
                Services
              </Heading>

              <FormControl
                isInvalid={errors.services?.navigate !== undefined}
                isRequired
              >
                <FormLabel>Navigation</FormLabel>
                <Input
                  placeholder="Navigation service name"
                  // Disable HTML validation
                  isRequired={false}
                  {...register('services.navigate', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.services?.navigate &&
                    errors.services?.navigate.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.services?.reset !== undefined}
                isRequired
              >
                <FormLabel>Reset</FormLabel>
                <Input
                  placeholder="Reset service name"
                  // Disable HTML validation
                  isRequired={false}
                  {...register('services.reset', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.services?.reset && errors.services?.reset.message}
                </FormErrorMessage>
              </FormControl>

              <Divider />

              <Heading size="md" fontWeight="semibold" alignSelf="start">
                Actions
              </Heading>

              <FormControl
                isInvalid={errors.actions?.locomotion !== undefined}
                isRequired
              >
                <FormLabel>Locomotion</FormLabel>
                <Input
                  placeholder="Locomotion server name"
                  // Disable HTML validation
                  isRequired={false}
                  {...register('actions.locomotion', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.actions?.locomotion &&
                    errors.actions?.locomotion.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button type="submit">Save</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;
