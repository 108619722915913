import React, { FC, useMemo, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  StackProps,
  Text,
  VStack,
  NumberInput,
  NumberInputField,
  useToast,
} from '@chakra-ui/react';
import roslib, { Vector3 } from 'roslib';
import ros from '../../../app/ros';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { selectLocations } from '../../../reducers/appStorageSlice';
import {
  hideWaypoint,
  selectWaypoint,
  setCurrentWaypoint,
  showWaypoint,
} from '../../../reducers/mapOverlaySlice';
import { selectOriginId } from '../../../reducers/mapSettingsSlice';
import { selectOffset } from '../../../reducers/sensorDataSlice';
import { latLngFromOdom } from '../../../app/utils';

const WaypointControl: FC<StackProps> = (props) => {
  const { google } = window;

  const locations = useAppSelector(selectLocations);
  const originId = useAppSelector(selectOriginId);
  const waypoint = useAppSelector(selectWaypoint);
  const offset = useAppSelector(selectOffset);
  const dispatch = useAppDispatch();

  const toast = useToast();
  const [heading, setHeading] = useState('');

  const origin = useMemo(
    () => locations.find((location) => location.id === originId)?.coordinates,
    [locations, originId]
  );

  const waypointData = (() => {
    const coordinates = waypoint.visible
      ? waypoint.pending?.coordinates
      : waypoint.current?.coordinates;

    if (!google || !origin || !coordinates) {
      return null;
    }

    const distanceFromOrigin =
      google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(origin),
        new google.maps.LatLng(coordinates)
      );
    const headingInDegrees = google.maps.geometry.spherical.computeHeading(
      new google.maps.LatLng(origin),
      new google.maps.LatLng(coordinates)
    );
    const heading = (headingInDegrees / 180) * Math.PI;
    const [x, y] = [Math.cos, Math.sin].map(
      (fn) => distanceFromOrigin * fn(heading)
    );
    const position = offset ?? new Vector3();
    const distance = Math.sqrt((x - position.x) ** 2 + (y - position.y) ** 2);

    return { x, y, distance };
  })();

  const handleCancel = () => {
    dispatch(hideWaypoint());
    setHeading('');
  };

  const handleConfirm = () => {
    ros.navigateClient?.callService(
      new roslib.ServiceRequest({
        x: waypointData?.x,
        y: waypointData?.y,
        final_heading: parseFloat(heading),
      }),
      () => {
        dispatch(setCurrentWaypoint());

        toast({
          description: 'Arrived at waypoint.',
          status: 'success',
          isClosable: true,
        });
      },
      (error) => {
        dispatch(setCurrentWaypoint());

        toast.close('waypoint');
        toast({
          title: 'Waypoint sending failed',
          description: `${error}.`,
          status: 'error',
          isClosable: true,
        });
      }
    );

    dispatch(
      setCurrentWaypoint({ ...waypoint.pending, heading: parseFloat(heading) })
    );
    handleCancel();

    toast({
      id: 'waypoint',
      description: 'New waypoint sent.',
      status: 'success',
      isClosable: true,
    });
  };

  const handleCancelCurrent = () => {
    ros.locomotionClient?.cancel();
    dispatch(setCurrentWaypoint());

    toast({
      description: 'Current waypoint cancelled.',
      status: 'success',
      isClosable: true,
    });
  };

  useEffect(() => {
    if (!origin || !waypoint.pending?.coordinates) {
      return;
    }

    const heading = google.maps.geometry.spherical.computeHeading(
      new google.maps.LatLng(latLngFromOdom(origin, offset)),
      new google.maps.LatLng(waypoint.pending.coordinates)
    );

    setHeading(heading.toFixed(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, dispatch, origin, waypoint.pending?.coordinates]);

  return waypoint.visible ? (
    <VStack {...props}>
      <Flex w="100%" justify="space-between">
        <Text fontWeight="semibold">Goal</Text>
        <Text w={40}>
          {waypointData
            ? `${waypointData.x.toFixed(2)} m, ${waypointData.y.toFixed(2)} m`
            : ''}
        </Text>
      </Flex>
      <Flex w="100%" justify="space-between">
        <Text fontWeight="semibold">Distance</Text>
        <Text w={40}>
          {waypointData ? `${waypointData.distance.toFixed(2)} m` : ''}
        </Text>
      </Flex>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        {...props}
      >
        <FormLabel mb={0} flexShrink={0}>
          Heading
        </FormLabel>
        <InputGroup size="sm" maxW={40}>
          <NumberInput
            size="sm"
            min={-180}
            max={180}
            precision={1}
            value={heading}
            onChange={(heading) => setHeading(heading)}
          >
            <NumberInputField placeholder="0" />
          </NumberInput>
          <InputRightAddon>&deg;</InputRightAddon>
        </InputGroup>
      </FormControl>
      <ButtonGroup size="sm" width="100%" spacing={4} pt={2}>
        <Button
          onClick={handleConfirm}
          disabled={!waypoint.pending?.coordinates || !heading}
          isFullWidth
        >
          Send
        </Button>
        <Button variant="outline" onClick={handleCancel} isFullWidth>
          Cancel
        </Button>
      </ButtonGroup>
    </VStack>
  ) : (
    <VStack {...props}>
      {waypoint.current && (
        <>
          <Flex w="100%" justify="space-between">
            <Text fontWeight="semibold">Current Goal</Text>
            <Text w={40}>
              {waypointData
                ? `${waypointData.x.toFixed(2)} m, ${waypointData.y.toFixed(
                    2
                  )} m`
                : ''}
            </Text>
          </Flex>
          <Flex w="100%" justify="space-between">
            <Text fontWeight="semibold">Distance</Text>
            <Text w={40}>
              {waypointData ? `${waypointData.distance.toFixed(2)} m` : ''}
            </Text>
          </Flex>
          <Flex w="100%" justify="space-between">
            <Text fontWeight="semibold">Heading</Text>
            <Text w={40}>
              {waypoint.current
                ? `${waypoint.current.heading?.toFixed(1)}°`
                : ''}
            </Text>
          </Flex>
        </>
      )}
      <ButtonGroup size="sm" w="100%" spacing={4} pt={2}>
        <Button onClick={() => dispatch(showWaypoint())} isFullWidth>
          Send New
        </Button>
        <Button
          onClick={handleCancelCurrent}
          disabled={!waypoint.current}
          isFullWidth
        >
          Cancel Current
        </Button>
      </ButtonGroup>
    </VStack>
  );
};

export default WaypointControl;
