import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Vector3 } from 'roslib';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../app/store';
import { Polygon } from '../types/messages';

interface SonarInfo {
  minAngle: number;
  maxAngle: number;
  range: number;
}

export interface SensorDataState {
  offsets: Vector3[];
  heading: number;
  sonar: {
    info: SonarInfo;
    data: Polygon[];
  };
}

const initialState: SensorDataState = {
  offsets: [],
  heading: 0,
  sonar: {
    info: {
      minAngle: 0,
      maxAngle: 0,
      range: 0,
    },
    data: [],
  },
};

export const sensorDataSlice = createSlice({
  name: 'sensorData',
  initialState,
  reducers: {
    addOffset: (state, action: PayloadAction<Vector3>) => {
      state.offsets.push(action.payload);
    },
    clearOffsets: (state) => {
      state.offsets = state.offsets.slice(
        state.offsets.length - 1,
        state.offsets.length
      );
    },
    setHeading: (state, action: PayloadAction<number>) => {
      state.heading = action.payload;
    },
    setSonarInfo: (state, action: PayloadAction<SonarInfo>) => {
      state.sonar.info = action.payload;
    },
    setSonarData: (state, action: PayloadAction<Polygon[]>) => {
      state.sonar.data = action.payload;
    },
    reset: (state) => ({
      ...initialState,
      sonar: { ...initialState.sonar, info: state.sonar.info },
    }),
  },
});

export const {
  addOffset,
  clearOffsets,
  setHeading,
  setSonarInfo,
  setSonarData,
  reset: resetSensorData,
} = sensorDataSlice.actions;

export const selectSensorData = (state: RootState) => state.sensorData;
export const selectOffset = (state: RootState) =>
  state.sensorData.offsets[state.sensorData.offsets.length - 1];

export default sensorDataSlice.reducer;
