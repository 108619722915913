import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../app/store';

interface Waypoint {
  coordinates?: google.maps.LatLngLiteral;
  heading?: number;
}

export interface MapOverlayState {
  location: {
    visible: boolean;
    coordinates?: google.maps.LatLngLiteral;
  };
  waypoint: {
    visible: boolean;
    current?: Waypoint;
    pending?: Waypoint;
  };
}

const initialState: MapOverlayState = {
  location: {
    visible: false,
  },
  waypoint: {
    visible: false,
  },
};

export const mapOverlaySlice = createSlice({
  name: 'mapOverlay',
  initialState,
  reducers: {
    showLocation: (state) => {
      state.waypoint.visible = false;
      delete state.waypoint.pending;
      state.location.visible = true;
    },
    hideLocation: (state) => {
      state.location.visible = false;
      delete state.location.coordinates;
    },
    setLocation: (
      state,
      action: PayloadAction<google.maps.LatLngLiteral | undefined>
    ) => {
      state.location.coordinates = action.payload;
    },
    showWaypoint: (state) => {
      state.location.visible = false;
      delete state.location.coordinates;
      state.waypoint.visible = true;
    },
    hideWaypoint: (state) => {
      state.waypoint.visible = false;
      delete state.waypoint.pending;
    },
    setCurrentWaypoint: (
      state,
      action: PayloadAction<Waypoint | undefined>
    ) => {
      state.waypoint.current = action.payload;
    },
    setPendingWaypointCoordinates: (
      state,
      action: PayloadAction<google.maps.LatLngLiteral | undefined>
    ) => {
      state.waypoint.pending = {
        ...state.waypoint.pending,
        coordinates: action.payload,
      };
    },
  },
});

export const {
  showLocation,
  hideLocation,
  setLocation,
  showWaypoint,
  hideWaypoint,
  setCurrentWaypoint,
  setPendingWaypointCoordinates,
} = mapOverlaySlice.actions;

export const selectMapOverlay = (state: RootState) => state.mapOverlay;
export const selectLocation = (state: RootState) => state.mapOverlay.location;
export const selectWaypoint = (state: RootState) => state.mapOverlay.waypoint;
export const selectCurrentWaypoint = (state: RootState) =>
  state.mapOverlay.waypoint.current;

export default mapOverlaySlice.reducer;
