import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../app/store';

export interface MapSettingsState {
  grid: {
    visible: boolean;
    distance: number;
    minZoom: number;

    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
  };
  vehicle: {
    visible: boolean;
    path: google.maps.SymbolPath;

    scale?: number;

    fillColor?: string;
    fillOpacity?: number;

    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
  };
  path: {
    visible: boolean;

    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
  };
  sonarFOV: {
    visible: boolean;

    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
  };
  sonarMap: {
    visible: boolean;

    fillColor?: string;
    fillOpacity?: number;

    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
  };
  origin: {
    visible: boolean;
    id: string;
    path: google.maps.SymbolPath;

    scale?: number;

    fillColor?: string;
    fillOpacity?: number;

    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
  };
  waypoint: {
    scale?: number;

    strokeColor?: string;
    strokeOpacity?: number;
    strokeWeight?: number;
  };
}

const initialState: MapSettingsState = {
  grid: {
    visible: false,
    distance: 10,
    minZoom: 20,

    strokeColor: '#FFFFFF',
    strokeOpacity: 0.3,
    strokeWeight: 3,
  },
  vehicle: {
    visible: true,
    path: 2,

    scale: 4,

    fillColor: '#000000',
    fillOpacity: 0,
    strokeColor: '#0000FF',

    strokeOpacity: 1,
    strokeWeight: 3,
  },
  path: {
    strokeColor: '#FFDD00',
    strokeOpacity: 1,
    strokeWeight: 3,

    visible: true,
  },
  sonarFOV: {
    visible: true,

    strokeColor: '#FF0000',
    strokeOpacity: 1,
    strokeWeight: 3,
  },
  sonarMap: {
    visible: false,

    fillColor: '#FF0000',
    fillOpacity: 0.4,

    strokeColor: '#0000FF',
    strokeOpacity: 0.2,
    strokeWeight: 1,
  },
  origin: {
    visible: true,
    id: 'w21z3kx7f',
    path: 0,

    scale: 5,

    fillColor: '#000000',
    fillOpacity: 1,

    strokeColor: '#FFFFFF',
    strokeOpacity: 1,
    strokeWeight: 4,
  },
  waypoint: {
    scale: 3,

    strokeColor: '#000000',
    strokeOpacity: 1,
    strokeWeight: 3,
  },
};

export const mapSettingsSlice = createSlice({
  name: 'mapSettings',
  initialState,
  reducers: {
    update: (_, action: PayloadAction<MapSettingsState>) => action.payload,
    setOriginId: (state, action: PayloadAction<string>) => {
      state.origin.id = action.payload;
    },
    resetOrigin: (state) => {
      state.origin.id = initialState.origin.id;
    },
  },
});

export const {
  update: updateMapSettings,
  setOriginId,
  resetOrigin,
} = mapSettingsSlice.actions;

export const selectMapSettings = (state: RootState) => state.mapSettings;
export const selectOriginId = (state: RootState) => state.mapSettings.origin.id;

export default mapSettingsSlice.reducer;
