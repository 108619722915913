import React, { FC } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  Spacer,
  Switch,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FaCog, FaEraser, FaInfoCircle, FaPlug, FaRedo } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import cleanDeep from 'clean-deep';
import roslib from 'roslib';
import ros from '../../app/ros';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import {
  MapSettingsState,
  selectMapSettings,
  updateMapSettings,
} from '../../reducers/mapSettingsSlice';
import { clearOffsets, resetSensorData } from '../../reducers/sensorDataSlice';
import SettingsModal from './SettingsModal';
import FillControl from './controls/FillControl';
import StrokeControl from './controls/StrokeControl';
import LocationControl from './controls/LocationControl';
import WaypointControl from './controls/WaypointControl';

interface Props extends BoxProps {
  handleReconnect: () => void;
}

const Panel: FC<Props> = ({ handleReconnect, ...props }) => {
  const mapSettings = useAppSelector(selectMapSettings);
  const dispatch = useAppDispatch();

  const toast = useToast();
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: mapSettings,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleReset = () => {
    ros.resetClient?.callService(
      new roslib.ServiceRequest({
        reset: true,
      }),
      () => {
        dispatch(resetSensorData());

        toast({
          description: 'Navigation reset.',
          status: 'success',
          isClosable: true,
        });
      },
      (error) => {
        toast({
          title: 'Navigation reset failed',
          description: `${error}.`,
          status: 'error',
          isClosable: true,
        });
      }
    );
  };

  const onChange = (settings: MapSettingsState) =>
    dispatch(
      updateMapSettings(
        cleanDeep(settings, {
          NaNValues: true,
        }) as MapSettingsState
      )
    );

  return (
    <>
      <Box position="relative" px={4} pt={16} pb={10} bg="white" {...props}>
        <VStack spacing={4}>
          <Heading size="lg" fontWeight="thin" textAlign="center">
            Bumblebee Mapper Interface
          </Heading>
          <Box w="100%">
            <form onChange={handleSubmit(onChange)}>
              <Accordion defaultIndex={[4, 5]} allowMultiple allowToggle>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex={1} textAlign="left" color="orange.500">
                      Grid
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0}>Enable</FormLabel>
                        <Switch {...register('grid.visible')} />
                      </FormControl>

                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0} flexShrink={0}>
                          Spacing
                          <Tooltip
                            label="Spacing between grid lines (in meters)"
                            closeOnClick={false}
                            placement="top"
                          >
                            <IconButton
                              variant="link"
                              size="sm"
                              colorScheme="gray"
                              aria-label="Info"
                              icon={<FaInfoCircle />}
                              _focus={{
                                ring: 0,
                              }}
                            />
                          </Tooltip>
                        </FormLabel>
                        <NumberInput size="sm" min={0} maxW={24}>
                          <NumberInputField
                            placeholder="10"
                            {...register('grid.distance', {
                              valueAsNumber: true,
                            })}
                          />
                        </NumberInput>
                      </FormControl>

                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0} flexShrink={0}>
                          Min Zoom
                          <Tooltip
                            label="Minimum zoom level (as defined by Google) needed to display the grid"
                            closeOnClick={false}
                            placement="top"
                          >
                            <IconButton
                              variant="link"
                              size="sm"
                              colorScheme="gray"
                              aria-label="Info"
                              icon={<FaInfoCircle />}
                              _focus={{
                                ring: 0,
                              }}
                            />
                          </Tooltip>
                        </FormLabel>
                        <NumberInput size="sm" min={0} maxW={24}>
                          <NumberInputField
                            placeholder="20"
                            {...register('grid.minZoom', {
                              valueAsNumber: true,
                            })}
                          />
                        </NumberInput>
                      </FormControl>

                      <StrokeControl name="grid" register={register} />
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex={1} textAlign="left" color="orange.500">
                      Vehicle
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0}>Enable</FormLabel>
                        <Switch {...register('vehicle.visible')} />
                      </FormControl>

                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0} flexShrink={0}>
                          Scale
                        </FormLabel>
                        <NumberInput size="sm" min={0} maxW={24}>
                          <NumberInputField
                            placeholder="1"
                            {...register('vehicle.scale', {
                              valueAsNumber: true,
                            })}
                          />
                        </NumberInput>
                      </FormControl>

                      <FillControl name="vehicle" register={register} />
                      <StrokeControl name="vehicle" register={register} />
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex={1} textAlign="left" color="orange.500">
                      Path
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0}>Enable</FormLabel>
                        <Switch {...register('path.visible')} />
                      </FormControl>

                      <StrokeControl name="path" register={register} />
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex={1} textAlign="left" color="orange.500">
                      Sonar
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0}>Enable FOV</FormLabel>
                        <Switch {...register('sonarFOV.visible')} />
                      </FormControl>

                      <StrokeControl name="sonarFOV" register={register} />
                    </VStack>

                    <Divider my={4} />

                    <VStack>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0}>Enable Map</FormLabel>
                        <Switch {...register('sonarMap.visible')} />
                      </FormControl>

                      <FillControl name="sonarMap" register={register} />
                      <StrokeControl name="sonarMap" register={register} />
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionButton>
                    <Box flex={1} textAlign="left" color="orange.500">
                      Origin
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0}>Enable</FormLabel>
                        <Switch {...register('origin.visible')} />
                      </FormControl>

                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0} flexShrink={0}>
                          Scale
                        </FormLabel>
                        <NumberInput size="sm" min={0} maxW={24}>
                          <NumberInputField
                            placeholder="1"
                            {...register('origin.scale', {
                              valueAsNumber: true,
                            })}
                          />
                        </NumberInput>
                      </FormControl>

                      <FillControl name="origin" register={register} />
                      <StrokeControl name="origin" register={register} />
                    </VStack>

                    <Divider my={4} />

                    <LocationControl
                      registerMapSettings={register}
                      setMapSettingsValue={setValue}
                    />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex={1} textAlign="left" color="orange.500">
                      Waypoint
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <VStack>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel mb={0} flexShrink={0}>
                          Scale
                        </FormLabel>
                        <NumberInput size="sm" min={0} maxW={24}>
                          <NumberInputField
                            placeholder="1"
                            {...register('waypoint.scale', {
                              valueAsNumber: true,
                            })}
                          />
                        </NumberInput>
                      </FormControl>

                      <StrokeControl
                        name="waypoint"
                        register={register}
                        hideOpacity
                      />
                    </VStack>

                    <Divider my={4} />

                    <WaypointControl />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </form>
          </Box>
        </VStack>
        <HStack position="absolute" top={0} left={0} w="100%" p={2}>
          <Tooltip label="Reset navigation">
            <IconButton
              colorScheme="blackAlpha"
              icon={<FaRedo />}
              aria-label="Reset navigation"
              onClick={handleReset}
            />
          </Tooltip>
          <Tooltip label="Clear path">
            <IconButton
              colorScheme="blackAlpha"
              icon={<FaEraser />}
              aria-label="Clear path"
              onClick={() => dispatch(clearOffsets())}
            />
          </Tooltip>
          <Tooltip label="Reconnect">
            <IconButton
              colorScheme="blackAlpha"
              icon={<FaPlug />}
              aria-label="Reconnect"
              onClick={handleReconnect}
            />
          </Tooltip>
          <Spacer />
          <IconButton
            colorScheme="blackAlpha"
            icon={<FaCog />}
            aria-label="Toggle panel"
            onClick={onOpen}
          />
        </HStack>
      </Box>

      <SettingsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Panel;
