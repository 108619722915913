import { Vector3 } from 'roslib';
import geohash from 'ngeohash';

export const latLngFromOdom = (
  origin: google.maps.LatLngLiteral,
  offset = new Vector3()
) => {
  const distance = Math.sqrt(offset.x ** 2 + offset.y ** 2);
  const heading = (Math.atan2(offset.y, offset.x) / Math.PI) * 180;

  return google.maps.geometry.spherical
    .computeOffset(new google.maps.LatLng(origin), distance, heading)
    .toJSON();
};

export const encode = (coordinates: google.maps.LatLngLiteral) =>
  geohash.encode(coordinates.lat, coordinates.lng);
