import React, { useCallback, useEffect } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Flex,
  IconButton,
  Slide,
  Spinner,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import ros from './app/ros';
import { useAppDispatch, useAppSelector } from './app/hook';
import { selectRosSettings } from './reducers/rosSettingsSlice';
import {
  addOffset,
  setHeading,
  setSonarData,
  setSonarInfo,
} from './reducers/sensorDataSlice';
import {
  Odometry,
  SonarInfo,
  SonarWorld,
  Vector3Stamped,
} from './types/messages';
import Map from './components/map/Map';
import Panel from './components/panel/Panel';
import Status from './components/status/Status';

const App = (): JSX.Element => {
  const toast = useToast();
  const isDesktop = useMediaQuery('(min-width: 62em)')[0];
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: isDesktop,
  });

  const rosSettings = useAppSelector(selectRosSettings);
  const dispatch = useAppDispatch();

  const connect = useCallback(() => {
    const showConnectingToast = () => {
      toast.closeAll();
      toast({
        duration: null,
        render: () => (
          <Alert status="info" variant="solid" rounded="md" pe="8">
            <Spinner me="3" />
            <AlertDescription>
              Connecting to rosbridge server...
            </AlertDescription>
            <CloseButton size="sm" position="absolute" top="1" right="1" />
          </Alert>
        ),
      });
    };

    const reconnect = () => {
      ros.reconnect();
      showConnectingToast();
    };

    ros.connect(`wss://${rosSettings.hostname}:${rosSettings.port}`);
    showConnectingToast();

    ros.on('connection', () => {
      toast.closeAll();
      toast({
        description: 'Connected to rosbridge server.',
        status: 'success',
        isClosable: true,
      });
    });

    ros.on('error', () => {
      toast.closeAll();
      toast({
        id: 'error',
        duration: null,
        render: () => (
          <Alert status="error" variant="solid" rounded="md" pe="8">
            <AlertIcon />
            <AlertDescription>
              Error connecting to rosbridge server.
            </AlertDescription>
            <Button
              colorScheme="whiteAlpha"
              size="xs"
              ms="3"
              onClick={reconnect}
            >
              Reconnect
            </Button>
            <CloseButton
              size="sm"
              position="absolute"
              top="1"
              right="1"
              onClick={() => toast.closeAll()}
            />
          </Alert>
        ),
      });
    });

    ros.on('close', () => {
      if (!toast.isActive('error')) {
        toast({
          description: 'Connection to rosbridge server closed.',
          status: 'info',
          isClosable: true,
        });
      }
    });
  }, [rosSettings.hostname, rosSettings.port, toast]);

  const subscribe = useCallback(() => {
    ros.updateNames({
      namespace: rosSettings.namespace,
      topics: rosSettings.topics,
      services: rosSettings.services,
      actions: rosSettings.actions,
    });

    ros.odomListener?.subscribe((message) =>
      dispatch(addOffset((message as Odometry).pose.pose.position))
    );

    ros.headingListener?.subscribe((message) =>
      dispatch(setHeading((message as Vector3Stamped).vector.z))
    );

    ros.sonarInfoListener?.subscribe((message) => {
      const sonarInfoMessage = message as SonarInfo;
      const [minAngle, maxAngle, range] = [
        sonarInfoMessage.fov_min,
        sonarInfoMessage.fov_max,
        sonarInfoMessage.max_range,
      ];

      dispatch(
        setSonarInfo({
          minAngle,
          maxAngle,
          range,
        })
      );

      ros.sonarInfoListener?.unsubscribe();
    });

    ros.sonarDataListener?.subscribe((message) =>
      dispatch(setSonarData((message as SonarWorld).contours))
    );
  }, [
    rosSettings.namespace,
    rosSettings.topics,
    rosSettings.services,
    rosSettings.actions,
    dispatch,
  ]);

  useEffect(connect, [connect]);
  useEffect(subscribe, [subscribe]);

  return (
    <>
      <Map position="absolute" h="100vh" w="100%" />
      <Box position="absolute" h="100vh" w="100%" pointerEvents="none">
        <Status
          position="absolute"
          bottom={0}
          left={0}
          right={isDesktop && isOpen ? '384px' : 0}
          mx="auto"
          mb={16}
          shadow="md"
          pointerEvents="auto"
          transition="right 0.4s ease-out"
        />
        <Slide direction={isDesktop ? 'right' : 'bottom'} in={isOpen}>
          <Flex justifyContent="flex-end">
            <Panel
              h="100vh"
              w={['100%', null, null, '96']}
              shadow="md"
              overflow="auto"
              pointerEvents="auto"
              handleReconnect={() => {
                connect();
                subscribe();
              }}
            />
          </Flex>
        </Slide>
        <IconButton
          colorScheme="blackAlpha"
          icon={<FaBars />}
          onClick={onToggle}
          position="absolute"
          bottom={0}
          right={0}
          m={2}
          pointerEvents="auto"
          aria-label="Toggle panel"
        />
      </Box>
    </>
  );
};

export default App;
