import React, { FC } from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
import { MapSettingsState } from '../../../reducers/mapSettingsSlice';

interface Props extends FormControlProps {
  name: 'vehicle' | 'sonarMap' | 'origin';
  register: UseFormRegister<MapSettingsState>;
}

const FillControl: FC<Props> = ({ name, register, ...props }) => (
  <>
    <FormControl
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <FormLabel mb={0} flexShrink={0}>
        Fill Color
      </FormLabel>
      <Input
        size="sm"
        maxW={24}
        placeholder="#000000"
        {...register(`${name}.fillColor`)}
      />
    </FormControl>

    <FormControl
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <FormLabel mb={0} flexShrink={0}>
        Fill Opacity
      </FormLabel>
      <NumberInput size="sm" min={0} max={1} maxW={24}>
        <NumberInputField
          placeholder="0"
          {...register(`${name}.fillOpacity`, {
            valueAsNumber: true,
          })}
        />
      </NumberInput>
    </FormControl>
  </>
);

export default FillControl;
