import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
/* eslint-disable import/no-cycle */
import appStorageReducer from '../reducers/appStorageSlice';
import mapOverlayReducer from '../reducers/mapOverlaySlice';
import mapSettingsReducer from '../reducers/mapSettingsSlice';
import rosSettingsReducer from '../reducers/rosSettingsSlice';
import sensorDataReducer from '../reducers/sensorDataSlice';
/* eslint-enable import/no-cycle */

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['appStorage', 'mapSettings', 'rosSettings'],
};

const rootReducer = combineReducers({
  appStorage: appStorageReducer,
  mapOverlay: mapOverlayReducer,
  mapSettings: mapSettingsReducer,
  rosSettings: rosSettingsReducer,
  sensorData: sensorDataReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
